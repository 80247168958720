import React, { useState, useEffect } from 'react'
import { Pagination, Button } from 'react-bootstrap'
import { Document, Page, pdfjs } from 'react-pdf'
import useWindowSize from '../hooks/use-window-size'
import resume from '../assets/Resume-Ron-Pruitt.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
const file = resume

export default function PdfLoader() {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [paginationItems, setPaginationItems] = useState([])
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const handlePageClick = (number) => {
    setPageNumber(number)
    window.scrollTo(0, 0)
  }
  const size = useWindowSize()
  useEffect(() => {
    if (numPages) {
      for (let number = 1; number <= numPages; number += 1) {
        setPaginationItems((paginationItems) => [
          ...paginationItems,
          <Pagination.Item
            key={number}
            active={number === pageNumber}
            onClick={() => handlePageClick(number)}
            variant="secondary"
            className="link-secondary"
          >
            {number}
          </Pagination.Item>,
        ])
      }
    }
    return () => setPaginationItems([])
  }, [numPages, pageNumber])
  return (
    <div className="pdfloader__main">
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        error="error loading pdf"
      >
        <Page pageNumber={pageNumber} width={Math.min(size.width * 0.8, 800)} />
      </Document>
      <div className="pdfloader_pagination__btn-group-container">
        <Pagination>{paginationItems}</Pagination>
        <div className="pdfloader_pagination__btn-download">
          <Button variant="primary" size="md" href={file} download>
            Download Resume
          </Button>
        </div>
      </div>
    </div>
  )
}
