import React from 'react'
import Layout from '../components/layout'
import PdfLoader from '../components/pdf-loader'

export default function resume() {
  return (
    <Layout>
      <PdfLoader />
    </Layout>
  )
}
